import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  editIcon: {
    color: theme.palette.grey[700],
  },
  disabled: {
    opacity: 0.5,
  },
  clickeable: {
    cursor: 'pointer',
  },
}));

export default useStyle;

import httpInstance from '../httpInstance';

const getAllCommercialCategoryService = async () => {
  let res;
  const endpoint = '/frontlabels/v1/business/details/backoffice';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getAllCommercialCategoryService;

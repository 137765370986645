/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { ButtonStyled } from "./styles";
import CustomInput from "../Form/CustomInput";

const JustificationDialog = ({
  isOpen,
  title,
  text,
  okFunction,
  notOkFunction,
  openCloseFx,
  actionsDisabled,
  acceptButtonLabel,
  control,
  Rules,
  errors,
}) => (
  <Dialog open={isOpen} aria-labelledby="dialog">
    <DialogTitle id="dialog">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{text}</DialogContentText>
      <CustomInput
        label="Indica el motivo (min. 20 caracteres) *"
        name="justification"
        control={control}
        multiline
        rule={Rules.justification}
        error={errors}
        inputProps={{
          maxLength: 200,
          minLength: 20,
        }}
      />
    </DialogContent>
    <DialogActions>
      <ButtonStyled
        error
        autoFocus
        onClick={() => {
          notOkFunction();
          openCloseFx(false);
        }}
      >
        Cancelar
      </ButtonStyled>
      <ButtonStyled
        autoFocus
        disabled={actionsDisabled}
        onClick={() => {
          okFunction();
          openCloseFx(false);
        }}
      >
        {acceptButtonLabel || "Aceptar"}
      </ButtonStyled>
    </DialogActions>
  </Dialog>
);

JustificationDialog.propTypes = {
  acceptButtonLabel: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  openCloseFx: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  okFunction: PropTypes.func.isRequired,
  notOkFunction: PropTypes.func.isRequired,
  actionsDisabled: PropTypes.bool,
  control: PropTypes.func.isRequired,
  Rules: PropTypes.func.isRequired,
  errors: PropTypes.func.isRequired,
};

export default JustificationDialog;

import httpInstance from '../httpInstance';

const updateCommercialCategoryService = async (dataParam) => {
  let res;
  const endpoint = '/frontlabels/v1/business/details';
  const dataSent = dataParam;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updateCommercialCategoryService;

/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { Toaster } from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';
import EditActionForm from '../ActionForm/EditActionForm';
import { successAlert } from '../../constants';
import { convertToSpanish } from './utils';

const BulkLoadPreview = ({
  open,
  data,
  setData,
  onClose,
  duplicatedMcc,
  onSave,
  sectorsList,
  setMainAlert,
  loading,
}) => {
  const [duplicatedMccTable, setDuplicatedMccTable] = useState({});
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const [defaultValues, setDefaultValues] = useState(
    {
      mcc: '',
      description: '',
      rubro: '',
      classification: '',
    },
  );

  const providerRef = React.useRef();
  const handleOnclose = () => {
    onClose();
  };

  useEffect(() => {
    const duplicatedMcchashTable = {};
    duplicatedMcc.forEach((item) => {
      const mcc = item.mcc;
      duplicatedMcchashTable[`${mcc}`] = item;
    });
    setDuplicatedMccTable(duplicatedMcchashTable);
  }, [duplicatedMcc]);

  const duplicatedValidation = () => {
    const duplicates = data.filter((item) => duplicatedMccTable[item.mcc]);
    return duplicates.length === 0;
  };

  const onSaveHandler = async () => {
    if (duplicatedValidation()) {
      onSave();
    } else {
      setAlert(
        {
          status: true,
          type: 'error',
          message: 'Parece que aún quedan categorías con MCC repetidas. Debes actualizar o eliminarlas antes de continuar',
        },
      );
    }
  };

  const updateBulkData = (mcc, payload) => {
    setData((prevData) => prevData.map((category) => {
      if (category.mcc === mcc) {
        return payload;
      }
      return category;
    }));
  };

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title="Previsualización de la carga"
        onClose={handleOnclose}
        onSave={onSaveHandler}
        enabledControls={!loading}
      >
        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ITBIS</TableCell>
                  <TableCell>Rubro</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Clasificación</TableCell>
                  <TableCell>Visible en App</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  const duplicated = duplicatedMccTable[row.mcc] !== undefined;
                  const customColor = { color: duplicated ? 'red' : 'black' };
                  const checkHandler = () => {
                    setData((prevData) => prevData.map((category) => {
                      if (category.mcc === row.mcc) {
                        return { ...category, visible: !category.visible };
                      }
                      return category;
                    }));
                    setMainAlert(successAlert);
                  };
                  const deleteHandler = () => {
                    setData((prevData) => prevData.filter((category) => category.mcc !== row.mcc));
                  };
                  const editHandler = () => {
                    setDefaultValues({
                      mcc: row.mcc,
                      description: row.description,
                      rubro: row.businessCategory,
                      classification: row.classification,
                    });
                    setPopUpAction({
                      open: true,
                      title: 'Editar categoría',
                      data: { mcc: row.mcc, active: row.itbis === 'SI', duplicatedMccTable },
                    });
                  };
                  return (
                    <TableRow key={row.mcc}>
                      <TableCell style={customColor}>{row.itbis}</TableCell>
                      <TableCell style={customColor}>{row.businessCategory}</TableCell>
                      <TableCell style={customColor}>{row.description}</TableCell>
                      <TableCell style={customColor}>{convertToSpanish(row.classification)}</TableCell>
                      <TableCell>
                        <Checkbox checked={row.visible} color="primary" onChange={checkHandler} />
                      </TableCell>
                      <TableCell>
                        <IconButton aria-label="edit" onClick={editHandler}>
                          <Edit />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={deleteHandler}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        <EditActionForm
          open={popUpAction.open}
          data={popUpAction.data}
          updateBulkData={updateBulkData}
          sectorsList={sectorsList}
          title={popUpAction.title}
          defaultValues={defaultValues}
          getCommercialCategories={() => {}}
          setAlert={setAlert}
          setDuplicatedMccTable={setDuplicatedMccTable}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
          }}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

BulkLoadPreview.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  setMainAlert: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  duplicatedMcc: PropTypes.array,
  sectorsList: PropTypes.shape({ [PropTypes.string]: PropTypes.object }).isRequired,
};

export default BulkLoadPreview;

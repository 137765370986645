/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    textAlign: 'center',
  },
  titleAlert: {
    color: '#E45501',
    fontWeight: 700,
    fontSize: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    paddingBottom: 20,
  },
  promoForm: {
    flexDirection: 'row',
    border: '1px solid #F1F1F1',
    padding: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  ButtonCancel: {
    marginLeft: 15,
    marginRight: 15,
  },
}));

const getColor = ({ error, theme }) => {
  if (error) {
    return `color: ${theme.firebrick};`;
  }

  return `color: ${theme.primary};`;
};

export const ButtonStyled = styled.button`
  align-items: center;
  justify-content: center;
  min-width: 64px;
  border: none;
  text-transform: uppercase;
  padding: 6px 16px;
  line-height: 1.75;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.02857em;
  background-color: ${({ theme }) => theme.white};
  box-shadow: none;
  ${getColor}
`;

import httpInstance from '../httpInstance';

const postBatchCommercialCategoriesService = async (body) => {
  let res;
  const endpoint = '/frontlabels/v1/business/details/batch';
  await httpInstance.post(
    endpoint,
    body,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default postBatchCommercialCategoriesService;

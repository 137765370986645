export const defaultData = {
  mcc: '',
  description: '',
  rubro: '',
  classification: '',
};
export const successMessage = 'Se ha actualizado la categoría comercial exitosamente';
export const errorMessage = 'Ha ocurrido un error, intente nuevamente.';
export const successAlert = { status: true, type: 'success', message: successMessage };
export const errorAlert = { status: true, type: 'success', message: errorMessage };
export const RISK_CLASIFICATION_ENG = {
  'Alto riesgo': 'HIGH_RISK',
  'Bajo riesgo': 'LOW_RISK',
  'No objeto de negocio': 'NO_BUSINESS_OBJECT',
};

const cleanString = (text) => {
  const cleanedString = text
    .replace(/\\n/g, ' ')
    .replace(/"/g, '')
    .replace(/\s+/g, ' ')
    .trim();

  return cleanedString;
};

export default cleanString;

/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Paper,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  CustomInput, Toaster, Loading, JustificationDialog,
} from '../../../../components';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import getCommerceInfoService from '../../../../services/commerceInfo/getCommerceInfoService';
import getCommercialCategoryService from '../../../../services/commercialCategory/getCommercialCategoryService';
import putCommercialCategoryService from '../../../../services/commercialCategory/putCommercialCategoryService';
import getDisabledField from './utils';
import {
  ALREADY_REGISTERED_ERROR, ALREADY_REGISTERED_ERROR_MESSAGE, DEFAULT_ERROR, NON_EXISTENT_RNC_ERROR,
  NON_EXISTENT_MCC_ERROR, MCC_DISABLED_ERROR,
} from './constants';
import postTapToPhoneService from '../../../../services/tapToPhone/postTapToPhoneService';
import CustomCheckbox from '../../../../components/Form/CustomCheckbox';
import FormCustomSwitch from '../../../../components/Form/FormCustomSwitch';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  defaultValues,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const providerRef = React.useRef();
  const [dataCommerce, setDataCommerce] = React.useState({});
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [formDataSaved, setFormDataSaved] = useState({});
  const {
    handleSubmit, control, errors, setValue, reset, getValues, watch,
  } = useForm({
    mode: 'all', defaultValues,
  });

  const documentNumberValue = watch('documentNumber');
  const commercialNameValue = watch('commercialName');
  const tapToPhoneActive = watch('activeTapOnPhone');
  const justification = watch('justification')?.toUpperCase();
  const modalActionDisabled = !justification
  || (justification?.length < 20 || justification?.length > 200);

  const showErrorAlert = (message) => setAlert({ status: true, type: 'error', message: message || DEFAULT_ERROR });

  const getCommerceData = async (documentNumberVal) => {
    setLoading(true);
    const dataResponse = await getCommerceInfoService(documentNumberVal.target.value);
    if (dataResponse.status === 200) {
      setLoading(false);
      setDataCommerce(dataResponse.data);
      setValue('commercialName', dataResponse.data.commercial_name);
      setValue('mcc', dataResponse.data.mcc);
      setValue('checkedItbis', dataResponse.data.itbis);
    } else if (dataResponse.status === 404) {
      setLoading(false);
      setValue('documentNumber', '');
      return showErrorAlert(NON_EXISTENT_RNC_ERROR);
    } else {
      setLoading(false);
      setValue('documentNumber', '');
      showErrorAlert();
    }
    return setLoading(false);
  };

  const showErrorMessageMCC = (message) => {
    setValue('mcc', '');
    showErrorAlert(message);
  };

  const handleSuccess = (commercialCategory) => {
    if (commercialCategory.enabled) {
      setValue('checkedItbis', commercialCategory.itbis);
    } else {
      showErrorMessageMCC(MCC_DISABLED_ERROR);
    }
  };

  const handleNonExistentMCC = () => {
    showErrorMessageMCC(NON_EXISTENT_MCC_ERROR);
    setValue('checkedItbis', false);
  };

  const handleDefaultError = () => {
    showErrorMessageMCC();
  };

  const getCommercialCategoryData = async (commercialCategoryInput) => {
    setLoading(true);
    try {
      const {
        data: commercialCategory,
        status,
      } = await getCommercialCategoryService(commercialCategoryInput.target.value);

      const statusHandler = {
        200: handleSuccess,
        404: handleNonExistentMCC,
        default: handleDefaultError,
      };

      const handleResponseStatus = statusHandler[status] || statusHandler.default;
      handleResponseStatus(commercialCategory);
    } catch (error) {
      handleDefaultError();
    }
    setLoading(false);
  };

  const createTapToPhone = async () => {
    const dataValues = getValues();
    const tapToPhoneBody = {
      commerce_id: dataCommerce?.commerce_id,
      legalName: dataValues?.commercialName,
      acquiringId: dataValues?.acquiringId,
      mcc: Number(dataValues?.mcc) || 0,
      terminalId: dataValues?.terminalId,
    };
    const tapToPhoneCreatedResponse = await postTapToPhoneService(
      tapToPhoneBody,
    );
    if (tapToPhoneCreatedResponse?.status !== 200) {
      if (tapToPhoneCreatedResponse?.data?.message === ALREADY_REGISTERED_ERROR) {
        return setAlert(
          { status: true, type: 'error', message: ALREADY_REGISTERED_ERROR_MESSAGE },
        );
      }
      return setAlert(
        { status: true, type: 'error', message: DEFAULT_ERROR },
      );
    }
    onClose();
  };

  const handleOnclose = () => {
    onClose();
  };
  const handleSave = async (formData) => {
    setLoading(true);
    try {
      if (data) {
        const {
          data: commerceInfoData,
          status,
        } = await getCommerceInfoService(data.documentNumber);

        if (status !== 200) showErrorAlert();
        if (status === 200) {
          const commercialData = {
            acquiring_id: formData.acquiringId,
            commerce_id: commerceInfoData.commerce_id,
            enabled: formData.activeTapOnPhone,
            mcc: Number(formData.mcc) || 0,
            legal_name: formData.commercialName,
            terminal_id: formData.terminalId,
            justification: formData.justification,
          };
          const { status: mccResponseStatus } = await putCommercialCategoryService(commercialData);
          if (mccResponseStatus === 200) {
            onClose();
          } else {
            showErrorAlert();
          }
        }
      } else {
        await createTapToPhone();
      }
    } catch (error) {
      showErrorAlert();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open && data !== null) reset(data);
    else reset(defaultValues);
  }, [open, data]);

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: commerceInfo,
        status,
      } = await getCommercialCategoryService(data.mcc);

      if (status === 200) {
        setValue('checkedItbis', commerceInfo.itbis);
      } else if (status === 404) {
        setValue('checkedItbis', false);
      }
    };

    if (data && data != null) {
      reset(data);
      fetchData();
    }
  }, [data]);

  const onSavePress = (formData) => {
    setFormDataSaved(formData);
    if (!data || data?.activeTapOnPhone === tapToPhoneActive) {
      handleSave(formData);
    } else {
      setOpenAlertConfirm(true);
    }
  };

  return (
    <SnackbarProvider ref={providerRef}>
      {loading ? <Loading /> : null}
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(onSavePress)}
        enabledControls
      >
        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="RNC/Cédula *"
                  name="documentNumber"
                  inputMode="numeric"
                  pattern="[0-9]*'"
                  type="number"
                  control={control}
                  rule={Rules.documentNumber}
                  error={errors}
                  onBlur={getCommerceData}
                  inputProps={{
                    maxLength: 11,
                    minLength: 9,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre del comercio*"
                  name="commercialName"
                  disabled={getDisabledField(documentNumberValue, commercialNameValue)}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="ID adquirencia"
                  name="acquiringId"
                  disabled={getDisabledField(documentNumberValue, commercialNameValue)}
                  control={control}
                  rule={Rules.cardnetId}
                  error={errors}
                  inputProps={{
                    minLength: 9,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="ID terminal"
                  name="terminalId"
                  disabled={getDisabledField(documentNumberValue, commercialNameValue)}
                  control={control}
                  rule={Rules.terminalId}
                  error={errors}
                  inputProps={{
                    minLength: 8,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Categoria comercial (MCC)*"
                  name="mcc"
                  inputMode="numeric"
                  pattern="[0-9]*'"
                  type="number"
                  control={control}
                  rule={Rules.mcc}
                  error={errors}
                  onBlur={getCommercialCategoryData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} />
              <Grid item xs={12} sm={12} md={4} lg={4} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                  <FormCustomSwitch
                    name="activeTapOnPhone"
                    label="Estado"
                    control={control}
                    error={errors}
                  />
                </Box>
              </Paper>
              <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                <Box display="flex" style={{ paddingTop: 0 }} justifyContent="flex-start" alignSelf="center">
                  <CustomCheckbox
                    name="checkedItbis"
                    control={control}
                    label="Itbis"
                    error={errors}
                    disabled
                  />
                </Box>
              </Paper>
            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
        </>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
      <JustificationDialog
        isOpen={openAlertConfirm}
        openCloseFx={setOpenAlertConfirm}
        title="Confirmar"
        text={`¿Esta seguro que quiere ${
          formDataSaved?.activeTapOnPhone ? 'activar' : 'desactivar'
        } tap to phone a este comercio?`}
        okFunction={() => handleSave({ ...formDataSaved, justification })}
        notOkFunction={() => setOpenAlertConfirm(false)}
        control={control}
        Rules={Rules}
        errors={errors}
        actionsDisabled={modalActionDisabled}
      />
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;

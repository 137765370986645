const paddingLeft = '40px';

export const columns = [
  {
    id: 'mcc',
    label: 'MCC',
    paddingLeft,
    width: 50,
    position: 1,
  },
  {
    id: 'description',
    label: 'Descripción',
    paddingLeft,
    width: 150,
    position: 2,
  },
  {
    id: 'rubro',
    label: 'Rubro',
    paddingLeft,
    width: 50,
    position: 3,
  },
  {
    id: 'classification',
    label: 'Clasificación',
    paddingLeft,
    width: 50,
    position: 4,
  },
  {
    id: 'itbis',
    label: 'ITBIS',
    paddingLeft,
    width: 50,
    position: 5,
  },
  {
    id: 'visibleInApp',
    label: 'Visible en App',
    paddingLeft,
    width: 50,
    position: 6,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 7,
  },
];

export const CLASSIFICATION_MAPPER = {
  HIGH_RISK: 'Alto riesgo',
  LOW_RISK: 'Bajo riesgo',
  NO_BUSINESS_OBJECT: 'No objeto de negocio',
};

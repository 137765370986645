/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { SnackbarProvider } from 'notistack';
import { useForm } from 'react-hook-form';
import { CSVLink } from 'react-csv';
import download from '../../../../assets/img/download.png';
import useStyle from './useStyle';
import {
  Toaster,
  CustomTable,
  CustomSwitch,
  Loading,
  JustificationDialog,
} from '../../../../components';
import COLUMNS from './constants';
import { getTapToPhoneRows, openModalEditTapToPhone } from './utils';
import putToggleTapToPhoneService from '../../../../services/tapToPhone/putToggleTapToPhoneService';
import { Rules } from '../../../../helpers/RHFRules';

const TapOnPhoneTable = (props) => {
  const classes = useStyle();
  const {
    tapToPhone,
    setPopUpAction,
    setDefaultValues,
    totalElementsData,
    onNext,
    onSelectPageSize,
    searchQuery,
    loading,
    onRowRefresh,
  } = props;
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [isActiveTapToPhone, setIsActiveTapToPhone] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();
  const [rowSelected, setRowSelected] = React.useState([]);
  const [TapToPhoneRows, setTapToPhoneRows] = React.useState([]);
  const [TapToPhoneCSVRows, setTapToPhoneCSVRows] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { control, errors, watch } = useForm({ justification: '' });
  const { justification } = watch(['justification']);
  const modalActionDisabled = !justification
  || (justification?.length < 20 || justification?.length > 200);

  const getSuccessNewData = (tapToPhoneParam) => {
    onRowRefresh({
      ...tapToPhoneParam,
      active_tap_to_phone: isActiveTapToPhone,
    });
    providerRef.current.enqueueSnackbar(
      `Se ha ${
        isActiveTapToPhone ? 'activado' : 'desactivado'
      } el tap to phone exitosamente`,
      {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      },
    );
  };

  const toggleTapToPhone = async (tapToPhoneData) => {
    const isEnabled = isActiveTapToPhone ? 'enable' : 'disable';
    setIsLoading(true);
    const showErrorMessage = () => {
      setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      });
    };
    try {
      const data = {
        justification: justification?.toUpperCase(),
        commerce_id: tapToPhoneData.commerce_id,
        new_value: isEnabled,
      };
      const response = await putToggleTapToPhoneService(data);

      if (response.status === 200) {
        setIsActiveTapToPhone((prev) => !prev);
        getSuccessNewData(tapToPhoneData);
      } else {
        showErrorMessage();
      }
    } catch (error) {
      showErrorMessage();
    }
    setIsLoading(false);
  };

  const handleChangeState = (event, rowData) => {
    setRowSelected(rowData);
    setOpenAlertConfirm(true);
    setIsActiveTapToPhone(event.target.checked);
  };

  const handleConfirmChecked = () => {
    toggleTapToPhone(rowSelected);
    setOpenAlertConfirm(false);
  };

  function createActions(active, tapToPhoneParam) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip
          title="Cambiar estado"
          aria-label="changeState"
          placement="top"
        >
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              onChange={
                tapToPhoneParam.creation_date_tap_to_phone !== null
                  ? (e) => handleChangeState(e, tapToPhoneParam)
                  : null
              }
              value={tapToPhoneParam}
              name="active"
            />
          </Box>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => {
            openModalEditTapToPhone(
              tapToPhoneParam,
              setDefaultValues,
              setPopUpAction,
            );
          }}
          disabled={!tapToPhoneParam.tap_to_phone_requested}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon
              className={
                tapToPhoneParam.tap_to_phone_requested
                  ? classes.editIcon
                  : classes.editIconReadOnly
              }
            />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }
  useEffect(() => {
    setTapToPhoneRows([]);
    setTapToPhoneCSVRows([]);
    getTapToPhoneRows(
      tapToPhone,
      createActions,
      setTapToPhoneRows,
      setTapToPhoneCSVRows,
    );
  }, [tapToPhone]);

  return (
    <SnackbarProvider ref={providerRef}>
      {isLoading ? <Loading /> : null}
      <CustomTable
        data={TapToPhoneRows}
        columns={COLUMNS}
        totalElementsData={totalElementsData}
        onNext={onNext}
        onSelectPageSize={onSelectPageSize}
        loading={loading}
        searchQuery={searchQuery}
      />

      <JustificationDialog
        isOpen={openAlertConfirm}
        openCloseFx={setOpenAlertConfirm}
        title="Confirmar"
        text={`¿Esta seguro que quiere ${
          isActiveTapToPhone ? 'activar' : 'desactivar'
        } tap to phone a este comercio?`}
        okFunction={() => handleConfirmChecked()}
        notOkFunction={() => setOpenAlertConfirm(false)}
        control={control}
        Rules={Rules}
        errors={errors}
        actionsDisabled={modalActionDisabled}
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

      <CSVLink
        data={TapToPhoneCSVRows}
        filename="tap-to-phone.csv"
        target="_blank"
      >
        <img src={download} alt="downloadCSV" />
      </CSVLink>
    </SnackbarProvider>
  );
};

export default TapOnPhoneTable;

/* eslint-disable no-unused-vars */
import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import Amplify from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';
import { ThemeProvider } from 'styled-components';
import { LoginScreen } from './screens';
import './App.css';
import { MainContainerLayout } from './Layouts';
import config from './config';
import {
  LOGIN,
  COMMISSIONS,
  REMITTANCES,
  BANNERS,
  PERSON_FEATURES_FLAG,
  TAXES,
  FEES,
  DASHBOARD,
  AMOUNTS,
  CARDS,
  REFERRALS,
  ALLIES,
  APPVERSION,
  APPCOMMERCEVERSION,
  POS_DEVICE,
  LIMIT_REPOS,
  DISTRIBUTION_CENTER,
  CARDS_QUERY,
  PROMOTIONS,
  DISTRIBUTION_POS,
  SEGMENTATION,
  TYPE_PROMOTIONS,
  PROVIDERS_SERVICE_PAY,
  PROVIDERS,
  PROMO_CATEGORIES,
  CATEGORIES_SERVICE_PAYMENT,
  CATEGORIES_SERVICE_RECHARGE,
  PROMOTIONS_APP_SCREEN,
  DISTRIBUTION_ZONE,
  FEATURED,
  ROLSCREENS,
  ROLREGISTER,
  ROLASSIGNMENT,
  PAYMENTS_LINK,
  TAP_TO_PHONE,
  NATIONAL_HOLIDAYS,
  COMMERCE_FEATURES_FLAG,
  EXPIRATION_DATE_REMITTANCE,
  COMMERCE_WEB_FEATURES_FLAG,
  REMITTANCES_NO_USER,
  CARDS_COST_LIMIT,
  COMMERCIAL_CATEGORY,
} from './navigation/screenNames';
import { THEME } from './theme/colors';

Amplify.configure(config.AMPLIFY);

// eslint-disable-next-line react/prop-types
const App = ({ OAuthSignIn }) => (
  <ThemeProvider theme={THEME}>
    <Router>
      <Switch>
        <Route exact path={`/${LOGIN}`}>
          <LoginScreen onClick={OAuthSignIn} />
        </Route>
        <Route exact path={`/${ROLSCREENS}`}>
          <MainContainerLayout title="Registro de pantallas" />
        </Route>
        <Route exact path={`/${ROLREGISTER}`}>
          <MainContainerLayout title="Registro de roles" />
        </Route>
        <Route exact path={`/${ROLASSIGNMENT}`}>
          <MainContainerLayout title="Asignación de roles" />
        </Route>
        <Route exact path={`/${TAXES}`}>
          <MainContainerLayout title="Impuestos" />
        </Route>
        <Route exact path={`/${DASHBOARD}`}>
          <MainContainerLayout title="Inicio" />
        </Route>
        <Route exact path={`/${COMMISSIONS}`}>
          <MainContainerLayout title="Comisiones" />
        </Route>
        <Route exact path={`/${FEES}`}>
          <MainContainerLayout title="Comisiones App" />
        </Route>
        <Route exact path={`/${AMOUNTS}`}>
          <MainContainerLayout title="Montos" />
        </Route>
        <Route exact path={`/${SEGMENTATION}`}>
          <MainContainerLayout title="Segmentación" />
        </Route>
        <Route exact path={`/${REMITTANCES}`}>
          <MainContainerLayout title="Remesas" />
        </Route>
        <Route exact path={`/${PERSON_FEATURES_FLAG}`}>
          <MainContainerLayout title="Feature Flag" />
        </Route>
        <Route exact path={`/${COMMERCE_FEATURES_FLAG}`}>
          <MainContainerLayout title="Feature Flag Comercios" />
        </Route>
        <Route exact path={`/${COMMERCE_WEB_FEATURES_FLAG}`}>
          <MainContainerLayout title="Feature Flag Web Comercios" />
        </Route>
        <Route exact path={`/${COMMERCIAL_CATEGORY}`}>
          <MainContainerLayout title="Categoría de comercios" />
        </Route>
        <Route exact path={`/${ALLIES}`}>
          <MainContainerLayout title="Super Aliados" />
        </Route>
        <Route exact path={`/${REFERRALS}`}>
          <MainContainerLayout title="Maestro" />
        </Route>
        <Route exact path={`/${PAYMENTS_LINK}`}>
          <MainContainerLayout title="Link de pagos" />
        </Route>
        <Route exact path={`/${TAP_TO_PHONE}`}>
          <MainContainerLayout title="Tap to phone" />
        </Route>
        <Route exact path={`/${CARDS}`}>
          <MainContainerLayout title="Costo por solicitud" />
        </Route>
        <Route exact path={`/${CARDS_COST_LIMIT}`}>
          <MainContainerLayout title="Límite de costo de tarjetas de crédito" />
        </Route>
        <Route exact path={`/${LIMIT_REPOS}`}>
          <MainContainerLayout title="Límite de reposiciones" />
        </Route>
        <Route exact path={`/${CARDS_QUERY}`}>
          <MainContainerLayout title="Consulta" />
        </Route>
        <Route exact path={`/${BANNERS}`}>
          <MainContainerLayout title="Banners Promocionales" />
        </Route>
        <Route exact path={`/${PROMOTIONS}`}>
          <MainContainerLayout title="Promociones" />
        </Route>
        <Route exact path={`/${TYPE_PROMOTIONS}`}>
          <MainContainerLayout title="Tipo de promociones" />
        </Route>
        <Route exact path={`/${PROMO_CATEGORIES}`}>
          <MainContainerLayout title="Categorias promociones" />
        </Route>
        <Route exact path={`/${PROMOTIONS_APP_SCREEN}`}>
          <MainContainerLayout title="Promociones Billet" />
        </Route>
        <Route exact path={`/${APPVERSION}`}>
          <MainContainerLayout title="Versión mínima requerida App Personas" />
        </Route>
        <Route exact path={`/${APPCOMMERCEVERSION}`}>
          <MainContainerLayout title="Versión mínima requerida App Comercios" />
        </Route>
        <Route exact path={`/${POS_DEVICE}`}>
          <MainContainerLayout title="POS Dispositivo" />
        </Route>
        <Route exact path={`/${DISTRIBUTION_POS}`}>
          <MainContainerLayout title="Provincias habilitadas para distribución" />
        </Route>
        <Route exact path={`/${DISTRIBUTION_CENTER}`}>
          <MainContainerLayout title="Centros de distribución" />
        </Route>
        <Route exact path={`/${DISTRIBUTION_ZONE}`}>
          <MainContainerLayout title="Zonas de distribución" />
        </Route>
        <Route exact path={`/${PROVIDERS_SERVICE_PAY}`}>
          <MainContainerLayout title="Proveedores" />
        </Route>
        <Route exact path={`/${PROVIDERS}`}>
          <MainContainerLayout title="Proveedores" />
        </Route>
        <Route exact path={`/${CATEGORIES_SERVICE_PAYMENT}`}>
          <MainContainerLayout title="Categorías" />
        </Route>
        <Route exact path={`/${CATEGORIES_SERVICE_RECHARGE}`}>
          <MainContainerLayout title="Categorías" />
        </Route>
        <Route exact path={`/${FEATURED}`}>
          <MainContainerLayout title="Promociones Destacadas" />
        </Route>
        <Route exact path={`/${NATIONAL_HOLIDAYS}`}>
          <MainContainerLayout title="Días feriados" />
        </Route>
        <Route exact path={`/${EXPIRATION_DATE_REMITTANCE}`}>
          <MainContainerLayout title="Vencimiento Remesas" />
        </Route>
        <Route exact path={`/${REMITTANCES_NO_USER}`}>
          <MainContainerLayout title="Remesas no user" />
        </Route>
        <Route exact path="*">
          <Redirect to={`/${LOGIN}`} />
        </Route>
      </Switch>
    </Router>
  </ThemeProvider>
);

export default withOAuth(App);

/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { ButtonStyled, useStyles } from './styles';

const CustomDialog = ({
  isOpen,
  title,
  text,
  firstButtonAction,
  secondButtonAction,
  setIsOpen,
  firstButtonLabel,
  secondButtonLabel,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="dialog"
    >
      <DialogTitle id="dialog" style={{ textAlign: 'center' }}>
        <Typography align="center" className={classes.titleAlert}>{title}</Typography>
      </DialogTitle>
      <DialogContent className={`${classes.root} ${classes.dialogContent}`} style={{ overflow: 'scroll' }}>
        {!!text && <DialogContentText>{text}</DialogContentText>}
        {!!children && children}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center', gap: 8 }}>
        {!!secondButtonAction && (
          <ButtonStyled
            error
            autoFocus
            onClick={() => {
              secondButtonAction();
              setIsOpen(false);
            }}
          >
              {secondButtonLabel || 'Cancelar'}
          </ButtonStyled>
        )}
        <ButtonStyled
          autoFocus
          onClick={() => {
            firstButtonAction();
            setIsOpen(false);
          }}
        >
          {firstButtonLabel || 'Aceptar'}
        </ButtonStyled>
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  firstButtonLabel: PropTypes.string,
  secondButtonLabel: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  firstButtonAction: PropTypes.func.isRequired,
  secondButtonAction: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default CustomDialog;

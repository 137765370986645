/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { CSVLink } from 'react-csv';
import EditIcon from '@material-ui/icons/Edit';
import { SnackbarProvider } from 'notistack';
import { Toaster, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import { columns, CLASSIFICATION_MAPPER } from './constants';
import { cleanString } from '../../../../utils';
import download from '../../../../assets/img/download.png';

const CommercialCategoriesTable = ({
  setPopUpAction,
  enteredFilter,
  commercialCategories,
  setDefaultValues,
  setUpdateMode,
}) => {
  const classes = useStyle();
  const [commercialCategoriesRows, setCommercialCategoriesRows] = useState([]);
  const [commercialCategoriesCopy, setCommercialCategoriesCopy] = useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = useRef();

  const filterInfoTable = (filter, codesMapped) => {
    const commercialCategoriesList = codesMapped != null ? codesMapped : commercialCategoriesCopy;
    const filteredList = commercialCategoriesList.filter(
      (commercialCategory) => commercialCategory.mcc
        .toString()
        .toLowerCase()
        .includes(filter.toLowerCase())
        || commercialCategory.description
          .toLowerCase()
          .includes(filter.toLowerCase()),
    );
    setCommercialCategoriesRows(filteredList);
  };

  const openModalEditFeatureFlag = (mccSelected) => {
    const commercialCategoryToBeEdited = commercialCategories.find(
      (commercialCategory) => commercialCategory.mcc === mccSelected,
    );

    const {
      // eslint-disable-next-line camelcase
      mcc, detail_name_es, rubro, classification, itbis,
    } = commercialCategoryToBeEdited;

    setDefaultValues({
      mcc,
      description: cleanString(detail_name_es),
      rubro,
      classification,
    });
    setPopUpAction({
      open: true,
      title: 'Editar categoría comercial',
      data: { active: itbis },
    });
    setUpdateMode(true);
  };

  const createActions = (mcc) => (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <IconButton
        edge="end"
        aria-label="Editar"
        size="small"
        onClick={() => openModalEditFeatureFlag(mcc)}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Editar" aria-label="edit" placement="top">
          <EditIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
    </Box>
  );

  const mapCommercialCategories = (commercialCategoriesParam) => {
    const commercialCategoriesInfo = commercialCategoriesParam?.map((commercialCategory) => ({
      mcc: commercialCategory.mcc,
      description: cleanString(commercialCategory.detail_name_es),
      rubro: commercialCategory.rubro,
      classification: CLASSIFICATION_MAPPER[commercialCategory.classification],
      itbis: commercialCategory.itbis ? 'Si' : 'No',
      visibleInApp: commercialCategory.justBackOffice ? 'No' : 'Si',
      actions: createActions(commercialCategory.mcc),
      id: commercialCategory.mcc,
      active: 1,
    }));

    setCommercialCategoriesRows(commercialCategoriesInfo);
    setCommercialCategoriesCopy(commercialCategoriesInfo);
    if (enteredFilter !== '') {
      filterInfoTable(enteredFilter, commercialCategoriesInfo);
    }
  };

  const getComercialCategoriesData = () => commercialCategoriesRows
    .map((
      {
        description, mcc, itbis, rubro, classification, visibleInApp,
      },
    ) => ({
      Descripción: description,
      MCC: mcc,
      'Retiene ITBIS': itbis.toUpperCase(),
      Rubro: rubro,
      Clasificación: classification,
      'Visible en App': visibleInApp.toUpperCase(),
    }));

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  useEffect(() => {
    setCommercialCategoriesRows([]);
    mapCommercialCategories(commercialCategories);
  }, [commercialCategories]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable data={commercialCategoriesRows} columns={columns} />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
      <CSVLink
        data={getComercialCategoriesData()}
        filename="commercial_categories.csv"
        target="_blank"
      >
        <img src={download} alt="downloadCSV" className={classes.clickeable} />
      </CSVLink>
    </SnackbarProvider>
  );
};

export default CommercialCategoriesTable;

import httpInstance from '../httpInstance';

const putToggleTapToPhoneService = async (dataSent) => {
  let res;
  const headers = { 'Content-Type': 'application/json' };

  const endpoint = '/back-office/v1/commerce-info/tap-to-phone/change-status';

  await httpInstance.put(
    endpoint, dataSent, { headers },
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default putToggleTapToPhoneService;

/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  CustomInput, CustomSwitch, CustomSelect, Loading,
} from '../../../../components';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import { getCommercialCategoryService } from '../../../../services';
import { errorAlert, successAlert } from '../../constants';
import { RISK_CLASIFICATION } from './constants';

const EditActionForm = ({
  open,
  data,
  title,
  onClose,
  defaultValues,
  sectorsList,
  updateBulkData,
  setDuplicatedMccTable,
  setAlert,
}) => {
  const [active, setActive] = React.useState(true);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const providerRef = React.useRef();

  const {
    handleSubmit, control, errors, reset, getValues,
  } = useForm({ defaultValues });

  const handleOnclose = () => {
    onClose();
  };
  const handleSave = async (dataParam) => {
    let responseStatus;
    let commercialCategory;
    setLoading(true);
    try {
      const {
        data: category,
        status,
      } = await getCommercialCategoryService(dataParam.mcc);
      commercialCategory = category;
      responseStatus = status;
    } catch (e) {
      setLoading(false);
      setAlert(errorAlert);
    }
    const shouldUpdate = !!(responseStatus === 200 && commercialCategory);
    const selectedRubro = sectorsList[dataParam.rubro];
    const body = {
      description: dataParam.description,
      mcc: Number(dataParam.mcc),
      itbis: active ? 'SI' : 'NO',
      visible: true,
      businessCategory: selectedRubro.description,
      classification: dataParam.classification,
    };
    if (shouldUpdate) {
      setPayload({ ...body, detail_id: commercialCategory.detail_id });
      setOpenAlertConfirm(true);
    }

    if (responseStatus === 404) {
      updateBulkData(data.mcc, body);
      setAlert(successAlert);
      onClose();
    }
    setLoading(false);
  };

  const handleUpdate = () => {
    updateBulkData(data.mcc, payload);
    const updatedTable = { ...data.duplicatedMccTable };
    delete updatedTable[data.mcc];
    setAlert(successAlert);
    setDuplicatedMccTable(updatedTable);
    setOpenAlertConfirm(false);
    onClose();
  };
  const getRubroOptions = (rubrosList) => rubrosList.map((rubro) => ({ value: rubro, label: rubro }));

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data && data != null) {
      setActive(data.active);
    }
  }, [data]);

  const getModalMessage = () => {
    const values = getValues();
    const selectedRubro = sectorsList[values.rubro];
    const text = `La categoria comercial que intentas agregar ya existe.
            Deseas actualizar los siguientes cambios?:
            ${values?.mcc} - ${values?.description} (${selectedRubro.description})`;
    return text;
  };

  return (
    <SnackbarProvider ref={providerRef}>
      {loading ? <Loading /> : null}
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >
        <>
          {openAlertConfirm && (
          <AlertConfirm
            isOpen={openAlertConfirm}
            tituloModal="¡Categoría existente!"
            textButtonAcept="Actualizar"
            mensajeModal={getModalMessage()}
            handleAccept={handleUpdate}
            onCancel
            onClose={() => setOpenAlertConfirm(false)}
          />
          )}
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="MCC *"
                  name="mcc"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Descripción *"
                  name="description"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Elegir Categoría/Rubro *"
                  name="rubro"
                  control={control}
                  error={errors}
                  options={getRubroOptions(Object.keys(sectorsList))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Clasificación de Riesgo *"
                  name="classification"
                  control={control}
                  error={errors}
                  options={RISK_CLASIFICATION}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                  <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                    <FormControlLabel
                      control={(
                        <CustomSwitch
                          checked={active}
                          onChange={(e) => setActive(e.target.checked)}
                          name="itbis"
                          color="black"
                        />
                    )}
                      label="Aplica Itbis"
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} />
              <Grid item xs={12} sm={12} md={4} lg={4} />

            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
        </>
      </ActionPopUp>
    </SnackbarProvider>
  );
};

EditActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  defaultValues: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  updateBulkData: PropTypes.func.isRequired,
  setDuplicatedMccTable: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  sectorsList: PropTypes.shape({ [PropTypes.string]: PropTypes.object }).isRequired,
};

export default EditActionForm;
